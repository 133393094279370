import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { Img } from "remotion";

const Template = ({ width, height }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let imageUrl =
    decodeURIComponent(queryParams.get("imageUrl")) || "example.png";
  let titleText = queryParams.get("titleText");
  let subtitleText = queryParams.get("subtitleText");
  const template = queryParams.get("template") || 1;

  let textOptions = [
    // Doctor
    {
      template: 1,
      variants: [
        {
          titleText: "I'm a Doctor",
          subtitleText: "but can't fix my own life",
        },
        {
          titleText: "This might hurt a bit",
          subtitleText: "",
        },
        {
          titleText: "You're healthy as a horse",
          subtitleText: "A horse with cancer",
        },
        {
          titleText: "Soo...",
          subtitleText: "How do you feel?",
        },
        {
          titleText: "Trust me",
          subtitleText: "I'm a doctor",
        },
        {
          titleText: "Keep calm",
          subtitleText: "and don't die",
        },
        {
          titleText: "Urine",
          subtitleText: "good hands",
        },
        {
          titleText: "I can't fix",
          subtitleText: "stupid",
        },
        {
          titleText: "Just cry",
          subtitleText: "on the inside",
        },
        {
          titleText: "Are you",
          subtitleText: "hangry?",
        },
      ],
    },
    // Business suit
    {
      template: 2,
      variants: [
        {
          titleText: "'Poor' or as I say",
          subtitleText: "Lazy",
        },
        {
          titleText: "Dreams don't work",
          subtitleText: "unless you do.",
        },
        {
          titleText: "Bank accounts",
          subtitleText: "don't fill themselves",
        },
        {
          titleText: "Out of my way",
          subtitleText: "Peasant",
        },
        {
          titleText: "My shirt",
          subtitleText: "costs more than your car",
        },
        {
          titleText: "Life's a game",
          subtitleText: "I'm winning in style",
        },
        {
          titleText: "My family tree",
          subtitleText: "grows money",
        },
        {
          titleText: "Bank account balance",
          subtitleText: "Generationally wealthy",
        },
        {
          titleText: "Rent? I prefer to",
          subtitleText: "buy the city",
        },
        {
          titleText: "I don't carry cash,",
          subtitleText: "I carry an ATM",
        },
        {
          titleText: "Dream big",
          subtitleText: "sleep later",
        },
      ],
    },
    // Hawaii
    {
      template: 3,
      variants: [
        {
          titleText: "Let's go on holiday",
          subtitleText: "",
        },
        {
          titleText: "Can you feel",
          subtitleText: "these Tropical Vibes?",
        },
        {
          titleText: "Living the Island Dream",
          subtitleText: "Wherever I roam",
        },
        {
          titleText: "Beach State of Mind",
          subtitleText: "All day, every day",
        },
        {
          titleText: "Tourist mode?",
          subtitleText: "Activated.",
        },
        {
          titleText: "Cool.",
          subtitleText: "Cool, cool, cool.",
        },
        {
          titleText: "Embrace",
          subtitleText: "the journey",
        },
        {
          titleText: "Find",
          subtitleText: "your passion",
        },
      ],
    },
    // Sheriff
    {
      template: 4,
      variants: [
        {
          titleText: "No lawbreakers",
          subtitleText: "Under my watch",
        },
        {
          titleText: "Stand down, partner",
          subtitleText: "",
        },
        {
          titleText: "The town's hero",
          subtitleText: "Protecting the innocent",
        },
        {
          titleText: "Call me Sheriff",
          subtitleText: "I'm armed with humor",
        },
        {
          titleText: "Criminal offense",
          subtitleText: "Too hot to handle",
        },
        {
          titleText: "Look at me!",
          subtitleText: "I'm the Sheriff",
        },
        {
          titleText: "I will",
          subtitleText: "take care of it",
        },
      ],
    },
    // Tank top
    {
      template: 5,
      variants: [
        {
          titleText: "I just flexed",
          subtitleText: "Sleeves fell off",
        },
        {
          titleText: "Suns out",
          subtitleText: "Guns out!",
        },
        {
          titleText: "Sleeveless",
          subtitleText: "and fearless",
        },
        {
          titleText: "Warning",
          subtitleText: "Muscles on display",
        },
        {
          titleText: "No sleeves",
          subtitleText: "No problem",
        },
        {
          titleText: "Never",
          subtitleText: "Give up",
        },
        {
          titleText: "Stay curious",
          subtitleText: "",
        },
      ],
    },
    // Hippie
    {
      template: 6,
      variants: [
        {
          titleText: "I'm just",
          subtitleText: "flowing the universe",
        },
        {
          titleText: "Let's keep it",
          subtitleText: "groovy",
        },
        {
          titleText: "Peace, love",
          subtitleText: "and good vibes",
        },
        {
          titleText: "Chillin'",
          subtitleText: "like a flower child",
        },
        {
          titleText: "Free spirit'",
          subtitleText: "wild heart",
        },
        {
          titleText: "Livin' in the state",
          subtitleText: "of harmony",
        },
        {
          titleText: "Just rollin'",
          subtitleText: "with it",
        },
        {
          titleText: "Rollin' into",
          subtitleText: "a higher state of mind",
        },
        {
          titleText: "Inhale the good",
          subtitleText: "exhale the bad",
        },
        {
          titleText: "Chase your dreams",
          subtitleText: "",
        },
        {
          titleText: "Celebrate",
          subtitleText: "every moment",
        },
      ],
    },
    // Astronaut
    {
      template: 7,
      variants: [
        {
          titleText: "Houston, we have",
          subtitleText: "a funny situation",
        },
        {
          titleText: "Do you want to see some",
          subtitleText: "Astro-nuts?",
        },
        {
          titleText: "Zero gravity",
          subtitleText: "Maximum fun",
        },
        {
          titleText: "Your jokes are",
          subtitleText: "out of this world",
        },
      ],
    },
    // Hoodie
    {
      template: 8,
      variants: [
        {
          titleText: "Nap, snack,",
          subtitleText: "repeat.",
        },
        {
          titleText: "I'm in a state",
          subtitleText: "Of total euphoria",
        },
        {
          titleText: "I understand",
          subtitleText: "Nothing",
        },
        {
          titleText: "I am",
          subtitleText: "dead inside",
        },
        {
          titleText: "I hate",
          subtitleText: "people",
        },
      ],
    },
    // Christmas -> no text
    // Colorful suit
    {
      template: 10,
      variants: [
        {
          titleText: "No Probllama!",
          subtitleText: "",
        },
        {
          titleText: "Pawsome!",
          subtitleText: "",
        },
        {
          titleText: "Donut worry",
          subtitleText: "Be happy!",
        },
        {
          titleText: "Muffin",
          subtitleText: "To see here",
        },
        {
          titleText: "You are an",
          subtitleText: "Aubergenius!",
        },
        {
          titleText: "You're one",
          subtitleText: "Smart cookie",
        },
        {
          titleText: "Corn-gradulations",
          subtitleText: "Keep it up",
        },
        {
          titleText: "Pear-fect work",
          subtitleText: "",
        },
        {
          titleText: "You are",
          subtitleText: "mango-nificent",
        },
        {
          titleText: "Egg-cellent!",
          subtitleText: "",
        },
        {
          titleText: "Fantabulous!",
          subtitleText: "",
        },
        {
          titleText: "Yay-mazing!",
          subtitleText: "",
        },
        {
          titleText: "Fabulicious!",
          subtitleText: "",
        },
      ],
    },
    // Priest
    {
      template: 11,
      variants: [
        {
          titleText: "My daily workout",
          subtitleText: "Lifting books & raising spirits",
        },
        {
          titleText: "That's me in the corner",
          subtitleText: "Loosing my religion",
        },
        {
          titleText: "Party don't start",
          subtitleText: "Without me",
        },
        {
          titleText: "I pray for you",
          subtitleText: "",
        },
        {
          titleText: "May God",
          subtitleText: "Have mercy on you",
        },
        {
          titleText: "May God guide you",
          subtitleText: "in your quest",
        },
        {
          titleText: "May God forgive you",
          subtitleText: "But I won't",
        },
        {
          titleText: "Let the sin begin!",
          subtitleText: "",
        },
        {
          titleText: "",
          subtitleText: "I saw that.",
        },
        {
          titleText: "I'm disappointed",
          subtitleText: "In the whole group",
        },
        {
          titleText: "Earth",
          subtitleText: "is a mess",
        },
        {
          titleText: "So it be",
          subtitleText: "So it is",
        },
      ],
    },
    // Bathrobe
    {
      template: 12,
      variants: [
        {
          titleText: "My brain",
          subtitleText: "is still sleeping",
        },
        {
          titleText: "I never laugh",
          subtitleText: "until I've had coffee",
        },
        {
          titleText: "I want coffee",
          subtitleText: "not your opinion",
        },
        {
          titleText: "More espresso",
          subtitleText: "less depresso",
        },
        {
          titleText: "Good morning",
          subtitleText: "",
        },
        {
          titleText: "Hello",
          subtitleText: "I'm tired",
        },
        {
          titleText: "I woke up",
          subtitleText: "like this",
        },
        {
          titleText: "Not today.",
          subtitleText: "",
        },
        {
          titleText: "I'm tired",
          subtitleText: "of this sh*t",
        },
        {
          titleText: "Is it Friday yet?",
          subtitleText: "",
        },
        {
          titleText: "Eat, sleep,",
          subtitleText: "repeat.",
        },
        {
          titleText: "Don't talk to me.",
          subtitleText: "",
        },
      ],
    },
    // Denim Jacket
    {
      template: 13,
      variants: [
        {
          titleText: "Sup Guys?",
          subtitleText: "",
        },
        {
          titleText: "Damn!",
          subtitleText: "",
        },
        {
          titleText: "That's lit!",
          subtitleText: "",
        },
        {
          titleText: "Ha Ha Ha Ha",
          subtitleText: "No.",
        },
        {
          titleText: "You're welcome",
          subtitleText: "",
        },
        {
          titleText: "Whatcha doin?",
          subtitleText: "",
        },
        {
          titleText: "Such wow",
          subtitleText: "Much wow",
        },
        {
          titleText: "Well, well, well",
          subtitleText: "How the turn tables",
        },
      ],
    },
    // Construction worker
    {
      template: 14,
      variants: [
        {
          titleText: "If I can't fix it",
          subtitleText: "Noone can",
        },
        {
          titleText: "You nailed it!",
          subtitleText: "",
        },
        {
          titleText: "I think its",
          subtitleText: "Superhero time",
        },
        {
          titleText: "I can't fix it",
          subtitleText: "But I'll try",
        },
        {
          titleText: "Everyone calm down",
          subtitleText: "I got this",
        },
        {
          titleText: "I can do it",
          subtitleText: "I watched YouTube",
        },
      ],
    },
    // Motor biker leather jacket??
    {
      template: 15,
      variants: [
        {
          titleText: "Living life on the",
          subtitleText: "rock 'n' roll edge.",
        },
        {
          titleText: "Ready to rock",
          subtitleText: "and steal the show.",
        },
        {
          titleText: "Eat. Sleep. Rock.",
          subtitleText: "Repeat.",
        },
        {
          titleText: "Born to ride,",
          subtitleText: "rules left behind",
        },
        {
          titleText: "Gasoline in my veins,",
          subtitleText: "adventure on wheels",
        },
        {
          titleText: "I'm turning heads",
          subtitleText: "with every ride",
        },
        {
          titleText: "I ride to",
          subtitleText: "break free",
        },
        {
          titleText: "The world",
          subtitleText: "Is my playground",
        },
      ],
    },
    // Unbuttoned -> no
    // Soccer
    {
      template: 17,
      variants: [
        {
          titleText: "Kicking goals",
          subtitleText: "Taking souls",
        },
        {
          titleText: "When you score",
          subtitleText: "they can't ignore",
        },
        {
          titleText: "Eat. Sleep. Play soccer.",
          subtitleText: "",
        },
        {
          titleText: "When you love soccer",
          subtitleText: "more than your friends",
        },
        {
          titleText: "Scoring goals",
          subtitleText: "and breaking hearts",
        },
      ],
    },
    // Summerdress -> no
    // Winter ski jacket -> no
    // Old -> no
    // hiker
    {
      template: 21,
      variants: [
        {
          titleText: "Let's go",
          subtitleText: "on an adventure!",
        },
        {
          titleText: "Nature",
          subtitleText: "Is calling",
        },
        {
          titleText: "Wow",
          subtitleText: "What a beautiful day",
        },
        {
          titleText: "Nature",
          subtitleText: "is my home",
        },
      ],
    },
    // Poloshirt
    {
      template: 22,
      variants: [
        {
          titleText: "I am silently",
          subtitleText: "Correcting your grammar",
        },
        {
          titleText: "You're fired!",
          subtitleText: "",
        },
        {
          titleText: "Amazing job!",
          subtitleText: "",
        },
        {
          titleText: "Nope!",
          subtitleText: "Try again.",
        },
        {
          titleText: "I couldn't",
          subtitleText: "Have done it better",
        },
        {
          titleText: "Wow",
          subtitleText: "This is boring",
        },
        {
          titleText: "You are doing",
          subtitleText: "Amazing",
        },
        {
          titleText: "You can do",
          subtitleText: "Better",
        },
        {
          titleText: "That's what",
          subtitleText: "she said",
        },
        {
          titleText: "I can't hear",
          subtitleText: "You",
        },
        {
          titleText: "Stop",
          subtitleText: "the violence",
        },
        {
          titleText: "I love",
          subtitleText: "that idea",
        },
      ],
    },
    // Plaid shirt -> no
    // Colorful old-fashioned tracksuit
    {
      template: 24,
      variants: [
        {
          titleText: "100 points",
          subtitleText: "for you!",
        },
        {
          titleText: "Good evening",
          subtitleText: "Ladies and Gentlemen",
        },
        {
          titleText: "Let's wing it!",
          subtitleText: "",
        },
        {
          titleText: "Get ready",
          subtitleText: "for some chaos",
        },
        {
          titleText: "You",
          subtitleText: "are WRONG.",
        },
        {
          titleText: "You",
          subtitleText: "are RIGHT!",
        },
        {
          titleText: "Absolutely",
          subtitleText: "",
        },
        {
          titleText: "I am",
          subtitleText: "positive",
        },
        {
          titleText: "I am",
          subtitleText: "negative",
        },
        {
          titleText: "Someone just",
          subtitleText: "got roasted",
        },
      ],
    },
  ];

  const generalVariants = [
    {
      titleText: "",
      subtitleText: "Nope.",
    },
    {
      titleText: "",
      subtitleText: "Definitely not.",
    },
    {
      titleText: "Let's do it!",
      subtitleText: "",
    },
    {
      titleText: "I love that!",
      subtitleText: "",
    },
    {
      titleText: "I need to",
      subtitleText: "sleep",
    },
    {
      titleText: "Okay",
      subtitleText: "",
    },
    {
      titleText: "What",
      subtitleText: "do you think?",
    },
    {
      titleText: "",
      subtitleText: "Let's make it happen",
    },
    {
      titleText: "Not today",
      subtitleText: "My friend",
    },
    {
      titleText: "Love it, live it",
      subtitleText: "Embrace it",
    },
    {
      titleText: "Whats",
      subtitleText: "Your take on it?",
    },
  ];

  if (titleText == null && subtitleText == null) {
    const activeTextElement = textOptions.find(
      (option) => option.template == template
    );

    let mergedVariants = [];

    if (activeTextElement == null) {
      // Merge generalVariants with activeTextElement.variants
      mergedVariants = [
        ...generalVariants,
        ...(activeTextElement ? activeTextElement.variants : []),
      ];
    } else {
      mergedVariants = activeTextElement.variants;
    }

    // Select a random variant from the merged list
    const randomVariant =
      mergedVariants[Math.floor(Math.random() * mergedVariants.length)];

    titleText = randomVariant ? randomVariant.titleText : "";
    subtitleText = randomVariant ? randomVariant.subtitleText : "";
  }

  const textPathRef = useRef(null);
  const pathRef = useRef(null);
  const subTextPathRef = useRef(null);
  const subPathRef = useRef(null);

  const gradients = [
    "gradient1",
    // "gradient2",
    // "gradient3",
    // "gradient4",
    // "gradient5",
    // "gradient6",
    // "gradient7",
    // "gradient8",
    // "gradient9",
    // "gradient10",
    // "gradient11",
    // "gradient12",
    // "gradient13",
    // "gradient14",
    // "gradient15",
    // "gradient16",
  ];
  const activeGradient =
    "url(#" + gradients[Math.floor(Math.random() * gradients.length)] + ")";

  const fonts = [
    "Lexend",
    "Luckiest Guy",
    "Bangers",
    "Braah One",
    "Sigmar",
    "Vina Sans",
    "Pacifico",
    "Alfa Slab One",
    "Passion One",
    "Carter One",
    "Paytone One",
    "Changa One",
  ];
  // const activeFont = fonts[Math.floor(Math.random() * fonts.length)];
  const activeFont = fonts[0];

  // useLayoutEffect(() => {
  //   const textPath = textPathRef.current;
  //   const path = pathRef.current;
  //   // const pathLength = path?.getTotalLength() * 1;
  //   // const text = textPath.textContent;

  //   let fontSize = 20;
  //   textPath.style.fontSize = `${fontSize}px`;

  //   // const lineHeight = textPath.getBBox().height;
  //   const maxHeight = 60;

  //   // Create a temporary canvas to measure the width of the characters
  //   const canvas = document.createElement("canvas");
  //   const context = canvas.getContext("2d");
  //   context.font = window.getComputedStyle(textPath).getPropertyValue("font");

  //   // const charLength = text.length;
  //   // const charWidths = Array.from(text).map(char => context.measureText(char).width);
  //   // const totalWidth = charWidths.reduce((a, b) => a + b, 0);
  //   // const averageWidth = totalWidth / charLength;

  //   // Use binary search to find the optimal font size
  //   let minSize = 0;
  //   let maxSize = 100;
  //   let bestSize = fontSize;

  //   while (minSize <= maxSize) {
  //     const midSize = Math.floor((minSize + maxSize) / 2);
  //     textPath.style.fontSize = `${midSize}px`;
  //     if (
  //       textPath.getComputedTextLength() <= pathLength &&
  //       midSize <= maxHeight
  //     ) {
  //       bestSize = midSize;
  //       minSize = midSize + 1;
  //     } else {
  //       maxSize = midSize - 1;
  //     }
  //   }

  //   textPath.style.fontSize = `${bestSize}px`;

  //   const subTextPath = subTextPathRef.current;
  //   const subPath = subPathRef.current;
  //   const subPathLength = subPath.getTotalLength() * 0.7;
  //   // const subText = subTextPath.textContent;

  //   let subFontSize = 20;
  //   subTextPath.style.fontSize = `${subFontSize}px`;

  //   // const subLineHeight = subTextPath.getBBox().height;
  //   const subMaxHeight = 60;

  //   // Create a temporary canvas to measure the width of the characters
  //   const subCanvas = document.createElement("canvas");
  //   const subContext = subCanvas.getContext("2d");
  //   subContext.font = window
  //     .getComputedStyle(subTextPath)
  //     .getPropertyValue("font");

  //   // const subCharLength = subText.length;
  //   // const subCharWidths = Array.from(subText).map(char => subContext.measureText(char).width);
  //   // const subTotalWidth = subCharWidths.reduce((a, b) => a + b, 0);
  //   // const subAverageWidth = subTotalWidth / subCharLength;

  //   // Use binary search to find the optimal font size
  //   let subMinSize = 0;
  //   let subMaxSize = 100;
  //   let subBestSize = subFontSize;

  //   while (subMinSize <= subMaxSize) {
  //     const subMidSize = Math.floor((subMinSize + subMaxSize) / 2);
  //     subTextPath.style.fontSize = `${subMidSize}px`;
  //     if (
  //       subTextPath.getComputedTextLength() <= subPathLength &&
  //       subMidSize <= subMaxHeight
  //     ) {
  //       subBestSize = subMidSize;
  //       subMinSize = subMidSize + 1;
  //     } else {
  //       subMaxSize = subMidSize - 1;
  //     }
  //   }

  //   subTextPath.style.fontSize = `${subBestSize}px`;
  // }, [textPathRef, pathRef, subTextPathRef, subPathRef]);

  return (
    <>
      <div
        style={{
          position: "relative",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: width,
          height: height,
          display: "flex",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <Img
          style={{
            borderRadius: "0px",
            width: width,
            objectFit: "cover",
          }}
          src={imageUrl}
        />
        <div
          style={{
            position: "absolute",
            top: 18,
            left: 15,
            right: 15,
            width: width - 30,
            height: height,
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            fontSize: "38px",
            fontWeight: 600,
            fontFamily: "Lexend",
            opacity: 0.9,
            textTransform: "uppercase",
            color: "#ffffff",
            zIndex: 10,
            textShadow:
              "2px 2.5px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
          }}
        >
          {titleText}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 18,
            left: 15,
            right: 15,
            width: width - 30,
            height: height,
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            fontSize: "38px",
            fontWeight: 600,
            fontFamily: "Lexend",
            opacity: 0.9,
            textTransform: "uppercase",
            color: "#ffffff",
            zIndex: 10,
            textShadow:
              "2px 2.5px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
          }}
        >
          {subtitleText}
        </div>
      </div>
    </>
  );
};

export default Template;
